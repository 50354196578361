const restaurants=[
   {
       "id": 0,
       "tags": [
           "comfort food",
           "casual dining"
       ],
       "name": "The Hungry Bear",
       "description": "The Hungry Bear serves up classic American comfort food in a casual, laid-back atmosphere. Stop by for a cheeseburger and milkshake, or try one of our daily specials!",
       "totalReviews": 3125,
       "timeToServe": 28,
       "rate": 2,
       "image": "https://source.unsplash.com/random/400x200/?comfort food,casual dining",
       "products": [
           {
               "restaurantId": 0,
               "id": 2,
               "name": "Grilled Cheese Sandwich",
               "price": 6.5,
               "description": "A classic grilled cheese sandwich made with white bread and cheddar cheese.",
               "tags": [
                   "Sandwich",
                   "Cheese"
               ],
               "image": "https://source.unsplash.com/random/400x200/?Sandwich,Cheese"
           },
           {
               "restaurantId": 0,
               "id": 3,
               "name": "Cheeseburger",
               "price": 8.5,
               "description": "A juicy cheeseburger made with a beef patty, topped with lettuce, tomato, and cheese.",
               "tags": [
                   "Burger",
                   "Beef"
               ],
               "image": "https://source.unsplash.com/random/400x200/?Burger,Beef"
           },
           {
               "restaurantId": 0,
               "id": 4,
               "name": "Caesar Salad",
               "price": 7,
               "description": "A classic Caesar salad made with romaine lettuce, chicken breast, parmesan cheese, croutons, and Caesar dressing.",
               "tags": [
                   "Salad",
                   "Chicken"
               ],
               "image": "https://source.unsplash.com/random/400x200/?Salad,Chicken"
           },
           {
               "restaurantId": 0,
               "id": 5,
               "name": "Fried Chicken",
               "price": 9.5,
               "description": "Crispy and delicious fried chicken, served with your choice of dipping sauce.",
               "tags": [
                   "Chicken",
                   "Fried"
               ],
               "image": "https://source.unsplash.com/random/400x200/?Chicken,Fried"
           },
           {
               "restaurantId": 0,
               "id": 6,
               "name": "BLT Sandwich",
               "price": 8,
               "description": "A classic BLT sandwich made with bacon, lettuce, tomato, and mayonnaise on toasted bread.",
               "tags": [
                   "Sandwich",
                   "Bacon"
               ],
               "image": "https://source.unsplash.com/random/400x200/?Sandwich,Bacon"
           },
           {
               "restaurantId": 0,
               "id": 7,
               "name": "Steak Frites",
               "price": 14,
               "description": "A juicy steak served with crispy French fries and your choice of sauce.",
               "tags": [
                   "Steak",
                   "Fries"
               ],
               "image": "https://source.unsplash.com/random/400x200/?Steak,Fries"
           },
           {
               "restaurantId": 0,
               "id": 8,
               "name": "Fish and Chips",
               "price": 12,
               "description": "Crispy and delicious battered fish served with golden fries.",
               "tags": [
                   "Fish",
                   "Chips"
               ],
               "image": "https://source.unsplash.com/random/400x200/?Fish,Chips"
           },
           {
               "restaurantId": 0,
               "id": 9,
               "name": "Mac and Cheese",
               "price": 8,
               "description": "Creamy macaroni pasta in a rich cheese sauce.",
               "tags": [
                   "Macaroni",
                   "Cheese"
               ],
               "image": "https://source.unsplash.com/random/400x200/?Macaroni,Cheese"
           },
           {
               "restaurantId": 0,
               "id": 10,
               "name": "Buffalo Wings",
               "price": 10,
               "description": "Juicy chicken wings coated in a spicy buffalo sauce, served with blue cheese dressing.",
               "tags": [
                   "Wings",
                   "Spicy"
               ],
               "image": "https://source.unsplash.com/random/400x200/?Wings,Spicy"
           },
           {
               "restaurantId": 0,
               "id": 11,
               "name": "Meatloaf",
               "price": 12,
               "description": "A classic meatloaf made with beef and pork, served with gravy.",
               "tags": [
                   "Meatloaf"
               ],
               "image": "https://source.unsplash.com/random/400x200/?Meatloaf"
           },
           {
               "restaurantId": 0,
               "id": 12,
               "name": "Chocolate Cake",
               "price": 6,
               "description": "A rich and decadent chocolate cake, topped with chocolate frosting.",
               "tags": [
                   "Cake",
                   "Chocolate"
               ],
               "image": "https://source.unsplash.com/random/400x200/?Cake,Chocolate"
           },
           {
               "restaurantId": 0,
               "id": 13,
               "name": "Strawberry Cheesecake",
               "price": 7,
               "description": "A classic cheesecake made with cream cheese and a graham cracker crust, topped with fresh strawberries.",
               "tags": [
                   "Cheesecake",
                   "Strawberry"
               ],
               "image": "https://source.unsplash.com/random/400x200/?Cheesecake,Strawberry"
           },
           {
               "restaurantId": 0,
               "id": 14,
               "name": "Apple Pie",
               "price": 5,
               "description": "A classic apple pie made with fresh, tart apples and a flaky pastry crust.",
               "tags": [
                   "Pie",
                   "Apple"
               ],
               "image": "https://source.unsplash.com/random/400x200/?Pie,Apple"
           },
           {
               "restaurantId": 0,
               "id": 15,
               "name": "Lemon Meringue Pie",
               "price": 6,
               "description": "A tangy lemon pie topped with a fluffy meringue.",
               "tags": [
                   "Pie",
                   "Lemon"
               ],
               "image": "https://source.unsplash.com/random/400x200/?Pie,Lemon"
           },
           {
               "restaurantId": 0,
               "id": 16,
               "name": "Vanilla Ice Cream",
               "price": 4,
               "description": "A scoop of creamy vanilla ice cream.",
               "tags": [
                   "Ice Cream",
                   "Vanilla"
               ],
               "image": "https://source.unsplash.com/random/400x200/?Ice Cream,Vanilla"
           }
       ]
   },
   {
       "id": 1,
       "tags": [
           "pizza"
       ],
       "name": "Pizza Palace",
       "description": "Pizza Palace serves up delicious, authentic Italian pizza. Our pies are made with only the freshest ingredients and cooked to perfection in our wood-fired oven.",
       "totalReviews": 2182,
       "timeToServe": 32,
       "rate": 2,
       "image": "https://source.unsplash.com/random/400x200/?pizza",
       "products": [
           {
               "restaurantId": 1,
               "id": 17,
               "name": "Margherita Pizza",
               "price": 12,
               "description": "A classic Margherita Pizza made with fresh tomato sauce, mozzarella cheese, basil and olive oil.",
               "tags": [
                   "Pizza",
                   "Tomato",
                   "Mozzarella"
               ],
               "image": "https://source.unsplash.com/random/400x200/?Pizza,Tomato,Mozzarella"
           },
           {
               "restaurantId": 1,
               "id": 18,
               "name": "Pepperoni Pizza",
               "price": 13.5,
               "description": "A pizza with tomato sauce, mozzarella cheese and pepperoni.",
               "tags": [
                   "Pizza",
                   "Pepperoni"
               ],
               "image": "https://source.unsplash.com/random/400x200/?Pizza,Pepperoni"
           },
           {
               "restaurantId": 1,
               "id": 19,
               "name": "Meat Lovers Pizza",
               "price": 15,
               "description": "A pizza loaded with meat toppings like pepperoni, sausage, bacon, and ham.",
               "tags": [
                   "Pizza",
                   "Meat"
               ],
               "image": "https://source.unsplash.com/random/400x200/?Pizza,Meat"
           },
           {
               "restaurantId": 1,
               "id": 20,
               "name": "Vegetable Pizza",
               "price": 14,
               "description": "A pizza loaded with fresh vegetables like bell peppers, onions, mushrooms, and olives.",
               "tags": [
                   "Pizza",
                   "Vegetable"
               ],
               "image": "https://source.unsplash.com/random/400x200/?Pizza,Vegetable"
           },
           {
               "restaurantId": 1,
               "id": 21,
               "name": "Spicy Sausage Pizza",
               "price": 14.5,
               "description": "A pizza with tomato sauce, mozzarella cheese, spicy sausage and jalapeños.",
               "tags": [
                   "Pizza",
                   "Sausage",
                   "Spicy"
               ],
               "image": "https://source.unsplash.com/random/400x200/?Pizza,Sausage,Spicy"
           },
           {
               "restaurantId": 1,
               "id": 22,
               "name": "Margherita Calzones",
               "price": 12,
               "description": "A classic Margherita Calzones filled with fresh tomato sauce, mozzarella cheese, basil and olive oil.",
               "tags": [
                   "Calzones",
                   "Tomato",
                   "Mozzarella"
               ],
               "image": "https://source.unsplash.com/random/400x200/?Calzones,Tomato,Mozzarella"
           },
           {
               "restaurantId": 1,
               "id": 23,
               "name": "Pepperoni Calzones",
               "price": 13.5,
               "description": "A calzone filled with tomato sauce, mozzarella cheese and pepperoni.",
               "tags": [
                   "Calzones",
                   "Pepperoni"
               ],
               "image": "https://source.unsplash.com/random/400x200/?Calzones,Pepperoni"
           },
           {
               "restaurantId": 1,
               "id": 24,
               "name": "Meatball Parmesan",
               "price": 14,
               "description": "Tender meatballs in a rich marinara sauce, topped with parmesan cheese.",
               "tags": [
                   "Meatballs",
                   "Parmesan"
               ],
               "image": "https://source.unsplash.com/random/400x200/?Meatballs,Parmesan"
           },
           {
               "restaurantId": 1,
               "id": 25,
               "name": "Spaghetti and Meatballs",
               "price": 12.5,
               "description": "Spaghetti pasta served with meatballs in a rich marinara sauce.",
               "tags": [
                   "Spaghetti",
                   "Meatballs"
               ],
               "image": "https://source.unsplash.com/random/400x200/?Spaghetti,Meatballs"
           },
           {
               "restaurantId": 1,
               "id": 26,
               "name": "Fettuccine Alfredo",
               "price": 13,
               "description": "Fettuccine pasta in a rich and creamy alfredo sauce.",
               "tags": [
                   "Fettuccine",
                   "Alfredo"
               ],
               "image": "https://source.unsplash.com/random/400x200/?Fettuccine,Alfredo"
           },
           {
               "restaurantId": 1,
               "id": 27,
               "name": "Lasagna",
               "price": 14,
               "description": "Layers of pasta, meat and cheese in a rich marinara sauce.",
               "tags": [
                   "Lasagna"
               ],
               "image": "https://source.unsplash.com/random/400x200/?Lasagna"
           },
           {
               "restaurantId": 1,
               "id": 28,
               "name": "Tiramisu",
               "price": 6,
               "description": "A classic Italian dessert made with ladyfingers, mascarpone cheese, and espresso.",
               "tags": [
                   "Tiramisu"
               ],
               "image": "https://source.unsplash.com/random/400x200/?Tiramisu"
           },
           {
               "restaurantId": 1,
               "id": 29,
               "name": "Cannoli",
               "price": 4,
               "description": "A traditional Sicilian pastry filled with sweet ricotta cheese.",
               "tags": [
                   "Cannoli"
               ],
               "image": "https://source.unsplash.com/random/400x200/?Cannoli"
           },
           {
               "restaurantId": 1,
               "id": 30,
               "name": "Chocolate Chip Cookies",
               "price": 2,
               "description": "Classic chocolate chip cookies made with butter, sugar, and chocolate chips.",
               "tags": [
                   "Cookies",
                   "Chocolate Chip"
               ],
               "image": "https://source.unsplash.com/random/400x200/?Cookies,Chocolate Chip"
           },
           {
               "restaurantId": 1,
               "id": 31,
               "name": "Gelato",
               "price": 5,
               "description": "Traditional Italian ice cream, made with fresh ingredients and slow churned for a creamy texture.",
               "tags": [
                   "Gelato"
               ],
               "image": "https://source.unsplash.com/random/400x200/?Gelato"
           }
       ]
   },
   {
       "id": 2,
       "tags": [
           "sushi"
       ],
       "name": "Sushi Paradise",
       "description": "Sushi Paradise serves up the freshest and most authentic Japanese sushi. Our chefs are experts in the art of sushi making and use only the highest quality ingredients.",
       "totalReviews": 3011,
       "timeToServe": 15,
       "rate": 5,
       "image": "https://source.unsplash.com/random/400x200/?sushi",
       "products": [
           {
               "restaurantId": 2,
               "id": 32,
               "name": "California Roll",
               "price": 8,
               "description": "A popular sushi roll filled with crab meat, avocado, and cucumber.",
               "tags": [
                   "Sushi",
                   "Roll",
                   "Crab"
               ],
               "image": "https://source.unsplash.com/random/400x200/?Sushi,Roll,Crab"
           },
           {
               "restaurantId": 2,
               "id": 33,
               "name": "Spicy Tuna Roll",
               "price": 9.5,
               "description": "A sushi roll filled with spicy tuna and topped with spicy mayo.",
               "tags": [
                   "Sushi",
                   "Roll",
                   "Tuna",
                   "Spicy"
               ],
               "image": "https://source.unsplash.com/random/400x200/?Sushi,Roll,Tuna,Spicy"
           },
           {
               "restaurantId": 2,
               "id": 34,
               "name": "Dragon Roll",
               "price": 12.5,
               "description": "A sushi roll filled with eel, avocado, and cucumber.",
               "tags": [
                   "Sushi",
                   "Roll",
                   "Eel",
                   "Avocado"
               ],
               "image": "https://source.unsplash.com/random/400x200/?Sushi,Roll,Eel,Avocado"
           },
           {
               "restaurantId": 2,
               "id": 35,
               "name": "Rainbow Roll",
               "price": 14,
               "description": "A sushi roll filled with a variety of fish, including tuna, salmon, and yellowtail.",
               "tags": [
                   "Sushi",
                   "Roll",
                   "Assorted fish"
               ],
               "image": "https://source.unsplash.com/random/400x200/?Sushi,Roll,Assorted fish"
           },
           {
               "restaurantId": 2,
               "id": 36,
               "name": "Vegetable Roll",
               "price": 8.5,
               "description": "A sushi roll filled with a variety of fresh vegetables, such as cucumber, avocado, and asparagus.",
               "tags": [
                   "Sushi",
                   "Roll",
                   "Vegetable"
               ],
               "image": "https://source.unsplash.com/random/400x200/?Sushi,Roll,Vegetable"
           },
           {
               "restaurantId": 2,
               "id": 37,
               "name": "Sashimi Platter",
               "price": 18,
               "description": "A platter of assorted raw fish, including tuna, salmon, and yellowtail.",
               "tags": [
                   "Sashimi",
                   "Assorted fish"
               ],
               "image": "https://source.unsplash.com/random/400x200/?Sashimi,Assorted fish"
           },
           {
               "restaurantId": 2,
               "id": 38,
               "name": "Chirashi",
               "price": 15,
               "description": "A bowl of sushi rice topped with a variety of raw fish, including tuna, salmon, and yellowtail.",
               "tags": [
                   "Sushi",
                   "Assorted fish",
                   "Rice"
               ],
               "image": "https://source.unsplash.com/random/400x200/?Sushi,Assorted fish,Rice"
           },
           {
               "restaurantId": 2,
               "id": 39,
               "name": "Tempura Platter",
               "price": 12,
               "description": "A platter of assorted seafood and vegetables, battered and deep-fried to a crispy golden brown.",
               "tags": [
                   "Tempura",
                   "Assorted"
               ],
               "image": "https://source.unsplash.com/random/400x200/?Tempura,Assorted"
           },
           {
               "restaurantId": 2,
               "id": 40,
               "name": "Ramen",
               "price": 12,
               "description": "A traditional Japanese dish featuring wheat noodles in a savory broth, topped with meat or vegetables and a variety of seasonings.",
               "tags": [
                   "Ramen",
                   "Japanese",
                   "Noodles"
               ],
               "image": "https://source.unsplash.com/random/400x200/?Ramen,Japanese,Noodles"
           },
           {
               "restaurantId": 2,
               "id": 41,
               "name": "Takoyaki",
               "price": 8,
               "description": "Fried octopus balls, often served as a street food in Japan, usually brushed with a sweet soy-based sauce and mayonnaise, topped with green laver.",
               "tags": [
                   "Takoyaki",
                   "Octopus",
                   "Japanese"
               ],
               "image": "https://source.unsplash.com/random/400x200/?Takoyaki,Octopus,Japanese"
           },
           {
               "restaurantId": 2,
               "id": 42,
               "name": "Green Tea Ice Cream",
               "price": 4.5,
               "description": "A scoop of green tea flavored ice cream, made with authentic green tea leaves.",
               "tags": [
                   "Ice Cream",
                   "Green Tea"
               ],
               "image": "https://source.unsplash.com/random/400x200/?Ice Cream,Green Tea"
           },
           {
               "restaurantId": 2,
               "id": 43,
               "name": "Mochi",
               "price": 3,
               "description": "A traditional Japanese sweet made from glutinous rice, often filled with sweet bean paste or ice cream.",
               "tags": [
                   "Mochi",
                   "Japanese"
               ],
               "image": "https://source.unsplash.com/random/400x200/?Mochi,Japanese"
           }
       ]
   },
   {
       "id": 3,
       "tags": [
           "curry"
       ],
       "name": "Taj Mahal Indian Cuisine",
       "description": "Taj Mahal Indian Cuisine offers a variety of authentic Indian dishes, including curries, tandoori, and biryani. All of our food is made with fresh, high-quality ingredients and traditional Indian spices.",
       "totalReviews": 1734,
       "timeToServe": 19,
       "rate": 2,
       "image": "https://source.unsplash.com/random/400x200/?curry",
       "products": [
           {
               "restaurantId": 3,
               "id": 44,
               "name": "Butter Chicken",
               "price": 12,
               "description": "A popular North Indian dish made with tender chicken in a creamy tomato-based sauce.",
               "tags": [
                   "Curry",
                   "Chicken"
               ],
               "image": "https://source.unsplash.com/random/400x200/?Curry,Chicken"
           },
           {
               "restaurantId": 3,
               "id": 45,
               "name": "Palak Paneer",
               "price": 10,
               "description": "A dish made with spinach and Indian cottage cheese in a flavorful gravy.",
               "tags": [
                   "Curry",
                   "Spinach",
                   "Paneer"
               ],
               "image": "https://source.unsplash.com/random/400x200/?Curry,Spinach,Paneer"
           },
           {
               "restaurantId": 3,
               "id": 46,
               "name": "Vindaloo",
               "price": 11,
               "description": "A spicy curry dish popular in Goa, made with meat, such as pork or beef, and a blend of spices like cumin, coriander, and chili.",
               "tags": [
                   "Curry",
                   "Spicy",
                   "Meat"
               ],
               "image": "https://source.unsplash.com/random/400x200/?Curry,Spicy,Meat"
           },
           {
               "restaurantId": 3,
               "id": 47,
               "name": "Biryani",
               "price": 14,
               "description": "A popular Indian dish made with long-grain rice, meat and a variety of spices and herbs.",
               "tags": [
                   "Biryani",
                   "Rice",
                   "Meat"
               ],
               "image": "https://source.unsplash.com/random/400x200/?Biryani,Rice,Meat"
           },
           {
               "restaurantId": 3,
               "id": 48,
               "name": "Samosa",
               "price": 4,
               "description": "A traditional Indian snack made with a savory pastry filled with spiced vegetables or meat.",
               "tags": [
                   "Samosa",
                   "Indian",
                   "Vegetable"
               ],
               "image": "https://source.unsplash.com/random/400x200/?Samosa,Indian,Vegetable"
           }
       ]
   },
   {
       "id": 4,
       "tags": [
           "sizzler",
           "spicyfood"
       ],
       "name": "Spicy Sizzler",
       "description": "Spicy Sizzler serves up a variety of sizzling hot Indian dishes, including curries, tandoori, and biryani. All of our food is prepared with fresh, high-quality ingredients and a mix of traditional Indian spices.",
       "totalReviews": 4925,
       "timeToServe": 24,
       "rate": 5,
       "image": "https://source.unsplash.com/random/400x200/?sizzler,spicyfood",
       "products": [
           {
               "restaurantId": 4,
               "id": 49,
               "name": "Chicken Tikka Sizzler",
               "price": 12.5,
               "description": "A sizzling plate of tender chicken tikka served with rice and vegetables.",
               "tags": [
                   "Sizzler",
                   "Chicken",
                   "Tikka"
               ],
               "image": "https://source.unsplash.com/random/400x200/?Sizzler,Chicken,Tikka"
           },
           {
               "restaurantId": 4,
               "id": 50,
               "name": "Lamb Rogan Josh Sizzler",
               "price": 14,
               "description": "A sizzling plate of tender lamb in a rich and spicy rogan josh curry sauce, served with rice and vegetables.",
               "tags": [
                   "Sizzler",
                   "Lamb",
                   "Rogan Josh"
               ],
               "image": "https://source.unsplash.com/random/400x200/?Sizzler,Lamb,Rogan Josh"
           },
           {
               "restaurantId": 4,
               "id": 51,
               "name": "Vegetable Korma Sizzler",
               "price": 12,
               "description": "A sizzling plate of mixed vegetables in a creamy korma sauce, served with rice and vegetables.",
               "tags": [
                   "Sizzler",
                   "Vegetable",
                   "Korma"
               ],
               "image": "https://source.unsplash.com/random/400x200/?Sizzler,Vegetable,Korma"
           },
           {
               "restaurantId": 4,
               "id": 52,
               "name": "Fish Curry Sizzler",
               "price": 13.5,
               "description": "A sizzling plate of fish fillet in a flavorful curry sauce, served with rice and vegetables.",
               "tags": [
                   "Sizzler",
                   "Fish",
                   "Curry"
               ],
               "image": "https://source.unsplash.com/random/400x200/?Sizzler,Fish,Curry"
           },
           {
               "restaurantId": 4,
               "id": 53,
               "name": "Prawn Biryani Sizzler",
               "price": 15,
               "description": "A sizzling plate of prawns and rice cooked with a blend of spices and herbs, served with vegetables.",
               "tags": [
                   "Sizzler",
                   "Prawn",
                   "Biryani"
               ],
               "image": "https://source.unsplash.com/random/400x200/?Sizzler,Prawn,Biryani"
           }
       ]
   },
   {
       "id": 5,
       "tags": [
           "Steak"
       ],
       "name": "The Steakhouse",
       "description": "The Steakhouse offers a variety of premium cuts of steak, cooked to perfection. Our menu also features seafood and non-steak options, along with an extensive wine list. All of our food is prepared with fresh, high-quality ingredients and cooked to order.",
       "totalReviews": 880,
       "timeToServe": 47,
       "rate": 3,
       "image": "https://source.unsplash.com/random/400x200/?Steak",
       "products": [
           {
               "restaurantId": 5,
               "id": 54,
               "name": "Filet Mignon",
               "price": 32,
               "description": "A 8oz cut of tender filet mignon steak, grilled to perfection.",
               "tags": [
                   "Steak",
                   "Filet Mignon"
               ],
               "image": "https://source.unsplash.com/random/400x200/?Steak,Filet Mignon"
           },
           {
               "restaurantId": 5,
               "id": 55,
               "name": "Ribeye Steak",
               "price": 36,
               "description": "A 12oz cut of flavorful ribeye steak, grilled to perfection.",
               "tags": [
                   "Steak",
                   "Ribeye"
               ],
               "image": "https://source.unsplash.com/random/400x200/?Steak,Ribeye"
           },
           {
               "restaurantId": 5,
               "id": 56,
               "name": "Surf and Turf",
               "price": 42,
               "description": "A 8oz filet mignon steak paired with a succulent lobster tail.",
               "tags": [
                   "Steak",
                   "Lobster"
               ],
               "image": "https://source.unsplash.com/random/400x200/?Steak,Lobster"
           },
           {
               "restaurantId": 5,
               "id": 57,
               "name": "Seafood Platter",
               "price": 38,
               "description": "A platter of seafood delicacies, including shrimp, scallops, and lobster tail, served with a side of seasonal vegetables.",
               "tags": [
                   "Seafood",
                   "Platter"
               ],
               "image": "https://source.unsplash.com/random/400x200/?Seafood,Platter"
           },
           {
               "restaurantId": 5,
               "id": 58,
               "name": "Caesar Salad",
               "price": 8,
               "description": "A classic Caesar salad made with romaine lettuce, croutons, parmesan cheese, and Caesar dressing.",
               "tags": [
                   "Salad",
                   "Caesar"
               ],
               "image": "https://source.unsplash.com/random/400x200/?Salad,Caesar"
           },
           {
               "restaurantId": 5,
               "id": 59,
               "name": "Baked Potato",
               "price": 5,
               "description": "A large baked potato, topped with butter and sour cream.",
               "tags": [
                   "Potato",
                   "Baked"
               ],
               "image": "https://source.unsplash.com/random/400x200/?Potato,Baked"
           },
           {
               "restaurantId": 5,
               "id": 60,
               "name": "Chocolate Lava Cake",
               "price": 8,
               "description": "A warm chocolate cake with a gooey melted chocolate center, served with vanilla ice cream.",
               "tags": [
                   "Cake",
                   "Chocolate"
               ],
               "image": "https://source.unsplash.com/random/400x200/?Cake,Chocolate"
           }
       ]
   },
   {
       "id": 6,
       "tags": [
           "Crepe"
       ],
       "name": "The Creperie",
       "description": "The Creperie offers a variety of sweet and savory crepes made with high-quality ingredients. Our menu features both traditional French crepes and creative twists on the classic dish.",
       "totalReviews": 518,
       "timeToServe": 28,
       "rate": 5,
       "image": "https://source.unsplash.com/random/400x200/?Crepe",
       "products": [
           {
               "restaurantId": 6,
               "id": 61,
               "name": "Classic Nutella Crepe",
               "price": 7,
               "description": "A classic crepe filled with Nutella and topped with powdered sugar.",
               "tags": [
                   "Crepe",
                   "Nutella"
               ],
               "image": "https://source.unsplash.com/random/400x200/?Crepe,Nutella"
           },
           {
               "restaurantId": 6,
               "id": 62,
               "name": "Ham and Cheese Crepe",
               "price": 9,
               "description": "A savory crepe filled with ham and cheese and topped with a side of mixed greens.",
               "tags": [
                   "Crepe",
                   "Ham",
                   "Cheese"
               ],
               "image": "https://source.unsplash.com/random/400x200/?Crepe,Ham,Cheese"
           },
           {
               "restaurantId": 6,
               "id": 63,
               "name": "Lemon Curd Crepe",
               "price": 8,
               "description": "A sweet crepe filled with lemon curd and topped with powdered sugar.",
               "tags": [
                   "Crepe",
                   "Lemon"
               ],
               "image": "https://source.unsplash.com/random/400x200/?Crepe,Lemon"
           },
           {
               "restaurantId": 6,
               "id": 64,
               "name": "Ratatouille Crepe",
               "price": 12,
               "description": "A savory crepe filled with ratatouille and topped with a side of mixed greens.",
               "tags": [
                   "Crepe",
                   "Ratatouille"
               ],
               "image": "https://source.unsplash.com/random/400x200/?Crepe,Ratatouille"
           },
           {
               "restaurantId": 6,
               "id": 65,
               "name": "Crab and Avocado Crepe",
               "price": 14,
               "description": "A savory crepe filled with crab meat and avocado, and topped with a side of mixed greens.",
               "tags": [
                   "Crepe",
                   "Crab",
                   "Avocado"
               ],
               "image": "https://source.unsplash.com/random/400x200/?Crepe,Crab,Avocado"
           }
       ]
   }
 ]
class DataService{

   async getRestaurants(): Promise<any[]>{
    return restaurants;
   }

}

export const dataService = new DataService();