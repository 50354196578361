import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, Transition as _Transition, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/logo.svg'


const _hoisted_1 = { class: "container-fluid h-100" }
const _hoisted_2 = { class: "navbar navbar-expand-lg navbar-light bg-light" }
const _hoisted_3 = { class: "container-fluid" }
const _hoisted_4 = {
  class: "collapse navbar-collapse",
  id: "navbarSupportedContent"
}
const _hoisted_5 = { class: "navbar-nav me-auto mb-2 mb-lg-0" }
const _hoisted_6 = { class: "nav-item" }
const _hoisted_7 = { class: "nav-item" }
const _hoisted_8 = { class: "nav-item" }
const _hoisted_9 = { class: "d-flex" }
const _hoisted_10 = { class: "row app-content no-gutters" }
const _hoisted_11 = { class: "data-container col" }
const _hoisted_12 = { class: "data-container col-4 hideIfEmpty" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_CartSummary = _resolveComponent("CartSummary")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("nav", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[3] || (_cache[3] = _createElementVNode("a", {
          class: "navbar-brand",
          href: "/"
        }, [
          _createElementVNode("img", {
            src: _imports_0,
            alt: "",
            height: "60"
          })
        ], -1)),
        _cache[4] || (_cache[4] = _createElementVNode("button", {
          class: "navbar-toggler",
          type: "button",
          "data-bs-toggle": "collapse",
          "data-bs-target": "#navbarSupportedContent",
          "aria-controls": "navbarSupportedContent",
          "aria-expanded": "false",
          "aria-label": "Toggle navigation"
        }, [
          _createElementVNode("span", { class: "navbar-toggler-icon" })
        ], -1)),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("ul", _hoisted_5, [
            _createElementVNode("li", _hoisted_6, [
              _createVNode(_component_router_link, {
                class: "nav-link",
                to: {name:'restaurants'}
              }, {
                default: _withCtx(() => _cache[0] || (_cache[0] = [
                  _createTextVNode("Restaurants")
                ])),
                _: 1
              })
            ]),
            _createElementVNode("li", _hoisted_7, [
              _createVNode(_component_router_link, {
                class: "nav-link",
                to: {name:'orders'}
              }, {
                default: _withCtx(() => _cache[1] || (_cache[1] = [
                  _createTextVNode("Orders")
                ])),
                _: 1
              })
            ]),
            _createElementVNode("li", _hoisted_8, [
              _createVNode(_component_router_link, {
                class: "nav-link",
                to: {name:'nearme'}
              }, {
                default: _withCtx(() => _cache[2] || (_cache[2] = [
                  _createTextVNode("Near ME")
                ])),
                _: 1
              })
            ])
          ]),
          _createElementVNode("form", _hoisted_9, [
            _createVNode(_component_CartSummary)
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_10, [
      _createElementVNode("div", _hoisted_11, [
        _createVNode(_component_router_view, null, {
          default: _withCtx(({ Component,route }) => [
            _createVNode(_Transition, {
              name: "custom-classes",
              "enter-active-class": route.meta.transitionEnter || 'animate__animated animate__slideInLeft',
              "leave-active-class": ""
            }, {
              default: _withCtx(() => [
                (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
              ]),
              _: 2
            }, 1032, ["enter-active-class"])
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_12, [
        _createVNode(_component_router_view, { name: "right-sidebar" })
      ])
    ])
  ]))
}