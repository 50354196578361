import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "d-flex w-100 justify-content-between" }
const _hoisted_2 = { class: "mb-1" }
const _hoisted_3 = { class: "text-end" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_avatar = _resolveComponent("b-avatar")!
  const _component_b_list_group_item = _resolveComponent("b-list-group-item")!
  const _component_b_list_group = _resolveComponent("b-list-group")!
  const _component_b_alert = _resolveComponent("b-alert")!
  const _component_b_spinner = _resolveComponent("b-spinner")!
  const _component_b_badge = _resolveComponent("b-badge")!
  const _component_b_button = _resolveComponent("b-button")!
  const _component_b_card_footer = _resolveComponent("b-card-footer")!
  const _component_b_card = _resolveComponent("b-card")!

  return (_openBlock(), _createBlock(_component_b_card, {
    title: "Order Items",
    class: "cart-container"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_b_list_group, null, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cart.items, (item) => {
            return (_openBlock(), _createBlock(_component_b_list_group_item, {
              key: item.product.id,
              class: "flex-column align-items-start"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, [
                  _createElementVNode("h5", _hoisted_2, [
                    _createVNode(_component_b_avatar, {
                      src: item.product.image
                    }, null, 8, ["src"]),
                    _createTextVNode(" " + _toDisplayString(item.product.name), 1)
                  ]),
                  _createElementVNode("small", _hoisted_3, [
                    _createTextVNode(" $" + _toDisplayString(item.product.price * item.quantity) + " ", 1),
                    _cache[1] || (_cache[1] = _createElementVNode("br", null, null, -1)),
                    _createElementVNode("small", null, [
                      _createTextVNode("$" + _toDisplayString(item.product.price) + " x ", 1),
                      _createElementVNode("b", null, _toDisplayString(item.quantity), 1)
                    ])
                  ])
                ]),
                _cache[2] || (_cache[2] = _createElementVNode("p", { class: "mb-1" }, null, -1))
              ]),
              _: 2
            }, 1024))
          }), 128))
        ]),
        _: 1
      }),
      _createVNode(_component_b_alert, {
        modelValue: _ctx.isCartEmpty,
        variant: "warning"
      }, {
        default: _withCtx(() => _cache[3] || (_cache[3] = [
          _createTextVNode(" You haven't selected any items yet. ")
        ])),
        _: 1
      }, 8, ["modelValue"]),
      _createVNode(_component_b_card_footer, null, {
        default: _withCtx(() => [
          _createVNode(_component_b_alert, {
            modelValue: _ctx.createdOrder,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.createdOrder) = $event)),
            variant: "success",
            dismissible: ""
          }, {
            default: _withCtx(() => [
              _cache[4] || (_cache[4] = _createTextVNode(" Order was ")),
              _createElementVNode("b", null, _toDisplayString(_ctx.createdOrder.orderCode), 1),
              _cache[5] || (_cache[5] = _createTextVNode(" created successfully!. Navigate to orders to see your order details and status, or proceed to add new items to create a new order. "))
            ]),
            _: 1
          }, 8, ["modelValue"]),
          _createVNode(_component_b_button, {
            class: "float-end",
            disabled: _ctx.isCartEmpty || _ctx.isCreatingOrder,
            onClick: _ctx.checkout,
            variant: "success"
          }, {
            default: _withCtx(() => [
              (_ctx.isCreatingOrder)
                ? (_openBlock(), _createElementBlock("span", _hoisted_4, [
                    _createVNode(_component_b_spinner),
                    _cache[6] || (_cache[6] = _createTextVNode(" Submitting your order... "))
                  ]))
                : (_openBlock(), _createElementBlock("span", _hoisted_5, [
                    _cache[7] || (_cache[7] = _createTextVNode(" Checkout ")),
                    _createVNode(_component_b_badge, { variant: "light" }, {
                      default: _withCtx(() => [
                        _createTextVNode("$ " + _toDisplayString(_ctx.totalAmount), 1)
                      ]),
                      _: 1
                    })
                  ]))
            ]),
            _: 1
          }, 8, ["disabled", "onClick"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}